import React, { useEffect, useState } from 'react';
import './App.css';
import treeImg from './imgs/logo192.png';
import { db } from './firebase-config'; 
import { collection, doc, deleteDoc, getDoc, setDoc, getDocs, query, onSnapshot } from 'firebase/firestore';

const useRealTimeCollection = (collectionPath) => {
  useEffect(() => {
    const q = query(collection(db, collectionPath));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
      });
      console.log(items);
    });

    return () => unsubscribe(); 
  }, [collectionPath]);
};

const ensureParentDocumentExists = async (parentNodeName, parentNodeDetails = {}) => {
  const parentRef = doc(db, "Lideres", parentNodeName);
  const parentSnap = await getDoc(parentRef);

  if (!parentSnap.exists()) {
    // console.log(`Creating missing parent document for ${parentNodeName}`);
    await setDoc(parentRef, { 
      name: parentNodeName, 
      details: parentNodeDetails 
    });
  } else {
    await setDoc(parentRef, { details: parentNodeDetails }, { merge: true });
  }
};

const addDescendantToFirestore = async (parentNode, name, details, side) => {
  await ensureParentDocumentExists(parentNode.name, parentNode.details);

  const leaderDetails = {
    email: details.email,
    wallet: details.wallet,
    valorContrato: details.valorContrato,
    quantoRendeu: details.quantoRendeu,
    saldoDisponivel: details.saldoDisponivel,
    pontos: details.pontos,
    lado: details.lado,
    patrocinador: details.patrocinador,
  };

  const nomineeRef = doc(collection(db, "Lideres", parentNode.name, "Indicados"), name);
  await setDoc(nomineeRef, { name, details, side }); 
  const nomineeAsLeaderRef = doc(db, "Lideres", name);
  const nomineeAsLeaderSnap = await getDoc(nomineeAsLeaderRef);
  if (nomineeAsLeaderSnap.exists()) {
    await setDoc(nomineeAsLeaderRef, { ...nomineeAsLeaderSnap.data(), details });
  }

};


const fetchLeaderAndNominees = async (leaderName) => {
  const leaderRef = doc(db, "Lideres", leaderName);
  const leaderDoc = await getDoc(leaderRef);

  if (!leaderDoc.exists()) {
    console.log("No such leader!");
    return;
  }

  const leaderData = leaderDoc.data();
  const nomineesCollectionRef = collection(db, "Lideres", leaderName, "Indicados");
  const nomineeDocs = await getDocs(nomineesCollectionRef);
  
  const nominees = nomineeDocs.docs.map(doc => doc.data());

  console.log("Leader:", leaderData, "Nominees:", nominees);
  return nominees;
};

async function fetchDescendants(leaderOrNomineeName, setIsLoading) {
  setIsLoading(true);

  try {
    // console.log(`Fetching descendants for: ${leaderOrNomineeName}`);
    const leaderRef = doc(db, "Lideres", leaderOrNomineeName);
    const leaderDoc = await getDoc(leaderRef);

    if (!leaderDoc.exists()) {
      // console.log(`No such leader or nominee found in Firestore: ${leaderOrNomineeName}`);
      setIsLoading(false);
      return { children: [] };
    }

    let entityData = leaderDoc.data();
    const nomineesCollectionRef = collection(db, "Lideres", leaderOrNomineeName, "Indicados");
    const querySnapshot = await getDocs(nomineesCollectionRef);

    let nominees = querySnapshot.docs.map(doc => {
      const nomineeData = doc.data();
      nomineeData.id = doc.id;
      return nomineeData;
    });

    // Manually sort nominees by side: left then right
    nominees = nominees.sort((a, b) => (a.side === 'left' ? -1 : 1));

    for (let nominee of nominees) {
      const descendantData = await fetchDescendants(nominee.id, setIsLoading) || { children: [] };
      nominee.children = descendantData.children;
    }
    
    entityData.children = nominees;
    return entityData;
  } catch (error) {
    console.error("Error fetching descendants:", error);
  } finally {
    setIsLoading(false);
  }
}

async function fetchNominees(leaderOrNomineeName) {
  console.log(`Fetching nominees for: ${leaderOrNomineeName}`); 
  const nomineesCollectionRef = collection(db, "Lideres", leaderOrNomineeName, "Indicados");
  const nomineeDocs = await getDocs(nomineesCollectionRef);
  const nominees = nomineeDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  console.log(`Nominees fetched for ${leaderOrNomineeName}:`, nominees);
  return nominees;
}

const AddDescendantForm = ({ onAdd }) => {
  const [name, setName] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(name);
    setName('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Indicado"
        required
      />
      <button type="submit">+</button>
    </form>
  );
};
const Node = ({
  node,
  parentNodeName, 
  onAddDescendant,
  onRemoveDescendant,
  onEditDescendant,
  onShowDetails,
  position,
  isRoot
}) => {

  const hasDetailsDefined = node.details && (
    node.details.wallet !== '' ||
    node.details.email !== '' ||
    node.details.valorContrato !== 0 ||
    node.details.quantoRendeu !== 0 ||
    node.details.saldoDisponivel !== 0 ||
    node.details.pontos !== 0 ||
    (node.details.lado && node.details.lado !== 'Não definido') ||
    (node.details.patrocinador && node.details.patrocinador !== 'Não definido')
  );

  const circleClass = `circle ${
    position === 'left' ? 'circle-left' : position === 'right' ? 'circle-right' : ''
    
  }`;
  
  const showDetails = async (node, parentNodeName) => {
    try {
      const nomineeIndicadosRef = doc(db, "Lideres", parentNodeName, "Indicados", node.name);
      let docSnap = await getDoc(nomineeIndicadosRef);
  
      if (!docSnap.exists()) {
        const nomineeLeaderRef = doc(db, "Lideres", node.name);
        docSnap = await getDoc(nomineeLeaderRef);
      }
  
      if (docSnap.exists()) {
        const details = docSnap.data().details;
        const detailsMessage = `
          Detalhes:
          Carteira: ${details.carteira || 'Não definido'}
          Email: ${details.email || 'Não definido'}
          Valor Contrato: ${details.valorContrato || 0}
          Quanto Rendeu: ${details.quantoRendeu || 0}
          Saldo Disponível: ${details.saldoDisponivel || 0}
          Pontos: ${details.pontos || 0}
          Lado: ${details.lado || 'Não definido'}
          Patrocinador: ${details.patrocinador || 'Não definido'}`;
        alert(detailsMessage);
      } else {
        alert("Details not found.");
      }
    } catch (error) {
      console.error("Error fetching nominee details:", error);
    }
  };
  
  return (
    <div className="node">
      <div className={circleClass}>{node.name}</div>
      {!isRoot && (
        <>
          <button onClick={() => {
            const isConfirmed = window.confirm("APAGAR esse USUÁRIO?");
            if (isConfirmed) {
              onRemoveDescendant(parentNodeName, node.name);
            }
          }} className="remove-btn">-</button>
          <button onClick={() => onEditDescendant(node, parentNodeName)} className="edit-btn">Editar</button>
          {hasDetailsDefined && (
            <button onClick={() => showDetails(node, parentNodeName)} className="details-btn">Mostrar</button>
          )}
        </>
      )}
      {}
      {node.children.length < 2 && <AddDescendantForm onAdd={(name) => onAddDescendant(node, name)} />}
      <div className="children">
        {node.children && Array.isArray(node.children) && node.children.map((child, index) => (
          <Node
            key={child.name}
            node={child}
            parentNodeName={node.name}
            onAddDescendant={onAddDescendant}
            onRemoveDescendant={onRemoveDescendant}
            onEditDescendant={onEditDescendant}
            position={index === 0 ? 'left' : 'right'}
            isRoot={false}
          />
        ))}
      </div>
    </div>
  );
};
                                    
const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [leader, setLeader] = useState({
    name: 'Billions', 
    children: [],
    details: {
      wallet: '',
      email: '',
      valorContrato: 0,
      quantoRendeu: 0,
      saldoDisponivel: 0,
      pontos: 0,
      lado: '',
      lider: '' 
    }
  });

  useEffect(() => {
    async function ensureRootLeaderExists() {

      const PASSWORD = process.env.REACT_APP_ACCESS_KEY;
      let attempts = 0;
      
      while (attempts < 3) {
        const userInput = prompt("SENHA:");
        if (userInput === PASSWORD) {
          alert("Acesso Permitido!"); 
          break;
        } else {
          attempts++;
          alert(`Senha incorreta! Tentativas restantes: ${3 - attempts}`);
        }
        
        if (attempts === 3) {
          alert("SENHA ERRADA!"); 
          window.location.href = "https://tree.delara.art";
          break; 
        }
      }
    
      const rootLeaderRef = doc(db, "Lideres", "Billions");
      const docSnap = await getDoc(rootLeaderRef);
  
      if (!docSnap.exists()) {
        // console.log("Billions leader not found, creating...");
        const details = {
          wallet: '',
          email: '',
          valorContrato: 0,
          quantoRendeu: 0,
          saldoDisponivel: 0,
          pontos: 0,
          lado: '',
          lider: '' 
        };
  
        await setDoc(rootLeaderRef, { name: "Billions", details });
        // console.log("Billions leader recreated successfully.");
      }
    }
  
    async function loadData() {
      await ensureRootLeaderExists(); 
      const leaderWithDescendants = await fetchDescendants('Billions', setIsLoading); 
  
      if (leaderWithDescendants) {
        setLeader(leaderWithDescendants);
      }
    }
  
    loadData();
  }, []); 

  const editDescendant = async (nodeToEdit, parentNodeName) => {
    try {
      const updatedDetails = {
        email: prompt("E-mail:", nodeToEdit.details.email || ""),
        carteira: prompt("Carteira:", nodeToEdit.details.wallet || ""),
        valorContrato: parseFloat(prompt("Valor do contrato:", nodeToEdit.details.valorContrato || 0)),
        quantoRendeu: parseFloat(prompt("Quanto rendeu?", nodeToEdit.details.quantoRendeu || 0)),
        saldoDisponivel: parseFloat(prompt("Saldo disponível:", nodeToEdit.details.saldoDisponivel || 0)),
        pontos: parseInt(prompt("Pontos:", nodeToEdit.details.pontos || 0), 10),
        lado: prompt("Lado (esquerdo/direito):", nodeToEdit.details.lado || ""),
        patrocinador: prompt("Patrocinador:", nodeToEdit.details.patrocinador || ""),
        };
  
      const nomineeIndicadosRef = doc(db, "Lideres", parentNodeName, "Indicados", nodeToEdit.name);
      await setDoc(nomineeIndicadosRef, { details: updatedDetails }, { merge: true });
  
      const nomineeLeaderRef = doc(db, "Lideres", nodeToEdit.name);
      const nomineeLeaderSnap = await getDoc(nomineeLeaderRef);
      if (nomineeLeaderSnap.exists()) {
        await setDoc(nomineeLeaderRef, { details: updatedDetails }, { merge: true });
      }
  
      setLeader(prevLeader => {
        const updateNodeDetails = (node) => {
          if (node.name === nodeToEdit.name) {
            node.details = updatedDetails;
          } else if (node.children) {
            node.children.forEach(child => updateNodeDetails(child));
          }
        };
  
        const newLeader = JSON.parse(JSON.stringify(prevLeader));
        updateNodeDetails(newLeader);
        return newLeader;
      });
  
      // console.log("Nominee details updated successfully in Firestore and local state.");
    } catch (error) {
      console.error("Failed to update nominee details in Firestore and local state:", error);
    }
  };
    
  const addDescendant = async (parentNode, name) => {
    setIsLoading(true);
    // console.log(`Attempting to add descendant: ${name} to parent: ${parentNode.name}`);
    const details = {
        wallet: '',
        email: '',
        valorContrato: 0,
        quantoRendeu: 0,
        saldoDisponivel: 0,
        pontos: 0,
        lado: '',
        patrocinador: ''
    };

    const side = parentNode.children && parentNode.children.length === 1 ? "right" : "left";
    // console.log(`Adding ${name} to ${parentNode.name} on side: ${side}`);

    try {
        await addDescendantToFirestore(parentNode, name, details, side);
        // console.log(`Descendant ${name} successfully added to Firestore under ${parentNode.name} on side: ${side}`);

        setLeader(prevLeader => {
            const updateDescendants = (node) => {
                if (node.name === parentNode.name) {
                    const newNominee = { name, details, children: [], side };
                    side === "left" ? node.children.unshift(newNominee) : node.children.push(newNominee);
                } else if (Array.isArray(node.children)) {
                    node.children.forEach(child => updateDescendants(child));
                }
            };

            const newLeader = JSON.parse(JSON.stringify(prevLeader));
            updateDescendants(newLeader);
            return newLeader;
        });
    } catch (error) {
        console.error("Error adding descendant to Firestore:", error);
    } finally {
        setIsLoading(false);
    }
};
      
  const removeDescendant = async (parentNodeName, nomineeName) => {
    setIsLoading(true); // Start showing the loading overlay
  
    try {
      await recursivelyRemoveFromLeaders(nomineeName);

      await deleteDoc(doc(db, "Lideres", parentNodeName, "Indicados", nomineeName));
      // console.log(`${nomineeName} successfully removed from Firestore under ${parentNodeName}`);
    
      if (parentNodeName !== "Billions") {
        const indicadosRef = collection(db, "Lideres", parentNodeName, "Indicados");
        const snapshot = await getDocs(indicadosRef);
        if (snapshot.empty) {
          await deleteDoc(doc(db, "Lideres", parentNodeName));
          // console.log(`${parentNodeName} has no more Indicados and has been removed from Lideres.`);
        }
      }
  
      setLeader(prevLeader => {
        const updateTree = (node, nameToRemove) => {
          node.children = node.children.filter(child => child.name !== nameToRemove);
          node.children.forEach(child => updateTree(child, nameToRemove));
          return node;
        };
      
        let newLeader = JSON.parse(JSON.stringify(prevLeader));
        newLeader = updateTree(newLeader, nomineeName);
        return newLeader;
      });
      
    } catch (error) {
      console.error("Error removing descendant from Firestore:", error);
    } finally {
      setIsLoading(false); // Hide the loading overlay regardless of the outcome
    }
  };
  
  async function recursivelyRemoveFromLeaders(leaderName) {
    try {
      // Define the reference to the leader/nominee in the "Lideres" collection.
      const leaderRef = doc(db, "Lideres", leaderName);
  
      // Define the reference to the "Indicados" subcollection of the leader/nominee.
      const indicadosRef = collection(leaderRef, "Indicados");
      const snapshot = await getDocs(indicadosRef);
  
      // Recursively delete all documents within the "Indicados" subcollection.
      for (const docSnapshot of snapshot.docs) {
        // Call the function recursively for each nominee in "Indicados" to handle nested nominees.
        await recursivelyRemoveFromLeaders(docSnapshot.id);
      }
  
      // After handling all nominees in "Indicados", delete the subcollection itself by deleting all its documents.
      for (const docSnapshot of snapshot.docs) {
        await deleteDoc(docSnapshot.ref); // Delete each document in "Indicados".
        // console.log(`This ${docSnapshot.id} was deleted from Indicados.`);
      }
  
      // Then, proceed to delete the leader/nominee document itself, if it's not "Billions".
      if (leaderName !== "Billions") {
        await deleteDoc(leaderRef); // Delete the leader/nominee document itself.
        // console.log(`This ${leaderName} was deleted from Lideres.`);
      }
    } catch (error) {
      console.error("Error removing leader or nominee from Firestore:", error);
    }
  }
  
return (
  <div className='App'>
    {isLoading && <div className="overlay">Carregando...<img src={treeImg} alt="Loading..." /></div>}
    <img src={treeImg} alt="binary-tree" width={'80px'} />
    <h1 className='title'>Árvore Binária</h1>
    
    <Node node={leader} 
        onAddDescendant={addDescendant} 
        onRemoveDescendant={removeDescendant} 
        onEditDescendant={editDescendant}
        isRoot={true} />
  </div>
);

};

export default App;